import { Type } from 'class-transformer';
import { UserSubscriptionDTO } from 'app/data/dto/user/UserSubscriptionDTO';
import { BaseUserDTO } from 'app/data/dto/user/BaseUserDTO';

export class UserDetailsDTO extends BaseUserDTO {
  public email: string;

  public enabled?: boolean;

  public firstLogin?: boolean;

  public nickname?: string;

  public permissions: string[];

  public phone?: string;

  public hasCompletedTutorial: boolean;

  @Type(() => UserSubscriptionDTO)
  public subscription: UserSubscriptionDTO;
}