<ng-template #headingTemplate>
  <h1>{{ liveClassDetails?.title | replaceEmpty }}</h1>

  <button type="button" class="btn btn-outline-primary" (click)="onBackClick()">
    <svg-icon src="asset/image/icon/chevron-left.svg"></svg-icon>
    <span>{{ 'COMMON.BACK' | translate }}</span>
  </button>
</ng-template>

<section class="section">
  <dl *ngIf="liveClassDetails" class="d-flex gap-2 flex-wrap">
    <dt>{{ 'VIEW.MAIN.LIVE_CLASSES.COACHES' | translate }}:</dt>
    <dd>
      <ul class="list-unstyled mb-0 implode">
        <li *ngFor="let coach of liveClassDetails.coaches" class="d-inline">
          {{ coach.user.fullName }}
        </li>
      </ul>
    </dd>
  </dl>

  <div class="zoom-container">
    <app-zoom-complete-view [autoInit]="true"
                            [features]="[ 'audio', 'preview', 'settings', 'video' ]"
                            [hideEndButton]="true">
    </app-zoom-complete-view>
  </div>
</section>