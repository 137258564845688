import { TemplatePortal } from '@angular/cdk/portal';
import { Component, OnDestroy, OnInit, TemplateRef, ViewChild, ViewContainerRef } from '@angular/core';
import { Transition } from '@uirouter/angular';
import { ZOOM_SERVICE_TOKEN } from 'app/component/ui/zoom/IZoomServiceInterface';
import {
  LiveClassesVideoCallService
} from 'app/component/view/main/liveClasses/zoom/service/LiveClassesVideoCallService';
import { MainLayoutComponent } from 'app/component/view/main/MainLayoutComponent';
import {
  ScheduledLiveClassSeniorDetailsResponseDTO
} from 'app/data/dto/scheduledLiveClass/senior/ScheduledLiveClassSeniorDetailsResponseDTO';
import { UserModel } from 'app/model/UserModel';
import { PortalUtil } from 'app/util/PortalUtil';

@Component({
  selector: 'app-live-classes-user-zoom',
  templateUrl: './LiveClassesUserZoomComponent.html',
  styleUrls: [ './LiveClassesUserZoomComponent.scss' ],
  providers: [
    { provide: ZOOM_SERVICE_TOKEN, useClass: LiveClassesVideoCallService },
  ]
})
export class LiveClassesUserZoomComponent implements OnInit, OnDestroy {
  @ViewChild('headingTemplate', { static: true })
  private readonly headingTemplate: TemplateRef<any>;

  public liveClassDetails: ScheduledLiveClassSeniorDetailsResponseDTO;

  constructor(private portalUtil: PortalUtil,
              public userModel:UserModel,
              public viewContainerRef: ViewContainerRef,
              private transition: Transition) {
  }

  public ngOnInit(): any {
    const meetingId = this.transition.params().id;
    this.portalUtil.attachPortalTo(
      MainLayoutComponent.PORTAL_OUTLET.HEADING,
      new TemplatePortal(this.headingTemplate, this.viewContainerRef)
    );
    this.userModel.getScheduledLiveClassDetails(meetingId).subscribe(res => {
      this.liveClassDetails = res
    })
  }

  public ngOnDestroy(): void {
    this.portalUtil.detachPortalFrom(MainLayoutComponent.PORTAL_OUTLET.HEADING);
  }

  public onBackClick(): void {
    history.back();
  }
}