import { Component, OnDestroy, OnInit, TemplateRef, ViewChild, ViewContainerRef } from '@angular/core';
import { Transition } from '@uirouter/angular';
import { ZOOM_SERVICE_TOKEN } from 'app/component/ui/zoom/IZoomServiceInterface';
import {
  WatchPartyVideoCallService
} from 'app/component/view/main/onDemand/senior/view/watchParty/view/zoom/service/WatchPartyVideoCallService';
import { WatchPartyDTO } from 'app/data/dto/watchParty/WatchPartyDTO';
import { WatchPartyModel } from 'app/model/WatchPartyModel';
import { PortalUtil } from 'app/util/PortalUtil';
import { OnDemandVideoModel } from 'app/model/OnDemandVideoModel';
import { VideoDetailsDTO } from 'app/data/dto/onDemandVideo/VideoDetailsDTO';
import { ViewUtil } from 'app/util/ViewUtil';
import { switchMap, throttleTime } from 'rxjs/operators';
import Player from '@vimeo/player';
import { StateUtil } from 'app/util/StateUtil';
import { MainLayoutComponent } from 'app/component/view/main/MainLayoutComponent';
import { TemplatePortal } from '@angular/cdk/portal';
import { SubscriptionStoreComponent } from 'app/component/SubscriptionStoreComponent';
import { WatchPartyEventDTO, WatchPartyEventStatus } from 'app/data/dto/watchParty/WatchPartyEventDTO';
import { ObjectUtil } from 'app/util/ObjectUtil';
import moment from 'moment';
import { fromEvent } from 'rxjs';
import { isNil } from 'lodash';
import { State } from 'app/common/State';

@Component({
  selector: 'app-watch-party-event',
  templateUrl: './WatchPartyEventComponent.html',
  styleUrls: [ './WatchPartyEventComponent.scss' ],
  providers: [
    { provide: ZOOM_SERVICE_TOKEN, useClass: WatchPartyVideoCallService },
  ]
})
export class WatchPartyEventComponent extends SubscriptionStoreComponent implements OnInit, OnDestroy {
  private static readonly THROTTLE_TIME: number = 500; // ms

  @ViewChild('headingTemplate', { static: true })
  private readonly headingTemplate: TemplateRef<any>;

  public watchPartyDetails: WatchPartyDTO

  public videoDetails: VideoDetailsDTO;

  public vimeoPlayer: Player;

  public inZoomSession: boolean = false;

  private currentUserLastSentEvent: WatchPartyEventDTO;

  private isProcessingRemoteEvent: boolean;

  constructor(private transition: Transition,
              private portalUtil: PortalUtil,
              public stateUtil: StateUtil,
              private viewUtil: ViewUtil,
              private viewContainerRef: ViewContainerRef,
              private onDemandVideoModel: OnDemandVideoModel,
              private watchPartyModel: WatchPartyModel) {
    super();
  }

  public ngOnInit(): void {
    this.portalUtil.attachPortalTo(
      MainLayoutComponent.PORTAL_OUTLET.HEADING,
      new TemplatePortal(this.headingTemplate, this.viewContainerRef)
    );

    this.getRelatedData(this.transition.params().id);
  }

  public ngOnDestroy(): void {
    this.portalUtil.detachPortalFrom(MainLayoutComponent.PORTAL_OUTLET.HEADING);
  }

  public endWatchParty(): void {
    this.watchPartyModel.end(this.watchPartyDetails.id)
      .subscribe(
        () => this.stateUtil.goToState(State.MAIN.SENIOR.ON_DEMAND.WATCH_PARTY.LIST),
        (error) => this.viewUtil.handleServerError(error)
      );
  }

  private getRelatedData(watchPartId: number): void {
    this.watchPartyModel.getById(watchPartId).pipe(
      switchMap((watchPartDetails: WatchPartyDTO) => {
        this.watchPartyDetails = watchPartDetails;
        return this.onDemandVideoModel.getVideo(this.watchPartyDetails.video.id);
      })
    ).subscribe(
      (video: VideoDetailsDTO) => {
        this.videoDetails = video;
        this.setupVimeoPlayer();
      },
      (error) => this.viewUtil.handleServerError(error)
    );
  }

  private setupVimeoPlayer(): void {
    this.vimeoPlayer = new Player('vimeo', {
      url: this.videoDetails.vimeoVideo.embedUrl,
      byline: false,
      portrait: false,
      responsive: true,
      title: false
    });

    this.vimeoPlayer.getDuration()
      .then((duration: number) => this.videoDetails.duration = duration);

    this.listenForVimeoPlayerEvents();
  }


  private listenForVimeoPlayerEvents(): void {
    fromEvent<Player.TimeEvent>(this.vimeoPlayer, 'play')
      .pipe(throttleTime(WatchPartyEventComponent.THROTTLE_TIME))
      .subscribe((timeEvent) => this.sendVimeoPlayerEvent(WatchPartyEventStatus.PLAY, timeEvent));

    fromEvent<Player.TimeEvent>(this.vimeoPlayer, 'seeked')
      .pipe(throttleTime(WatchPartyEventComponent.THROTTLE_TIME))
      .subscribe((timeEvent) => this.sendVimeoPlayerEvent(WatchPartyEventStatus.PLAY, timeEvent));

    fromEvent<Player.TimeEvent>(this.vimeoPlayer, 'pause')
      .pipe(throttleTime(WatchPartyEventComponent.THROTTLE_TIME))
      .subscribe((timeEvent) => this.sendVimeoPlayerEvent(WatchPartyEventStatus.STOP, timeEvent));
  }

  private sendVimeoPlayerEvent(status: WatchPartyEventStatus, timeEvent: Player.TimeEvent): void {
    if (this.isProcessingRemoteEvent) {
      setTimeout(() => this.isProcessingRemoteEvent = false, WatchPartyEventComponent.THROTTLE_TIME)
      return;
    }

    if (isNil(timeEvent?.seconds)) {
      return;
    }

    this.currentUserLastSentEvent = ObjectUtil.plainToClass(WatchPartyEventDTO, {
      clientTimestamp: moment().toDate(),
      vimeoTimestamp: timeEvent.seconds,
      status: status,
    });

    void this.watchPartyModel.sendEvent(this.watchPartyDetails.id, this.currentUserLastSentEvent)
      .subscribe({
        error: (error) => this.viewUtil.handleServerError(error)
      });
  }

  public connect(inZoomSession: boolean): void {
    this.inZoomSession = inZoomSession;

    if (!inZoomSession) {
      return;
    }

    this.watchPartyModel.getLastEvent(this.watchPartyDetails.id).pipe(
      switchMap((event: WatchPartyEventDTO) => {
        if (event) {
          this.processLastEvent(event);
        }

        return this.watchPartyModel.connect(this.watchPartyDetails.id);
      })
    ).subscribe(
      (event: WatchPartyEventDTO) => {
        this.processRemoteEvent(event);
      },
      (error) => this.viewUtil.handleServerError(error)
    );
  }

  private processLastEvent(event: WatchPartyEventDTO): void {
    let vimeoTimestamp: number = event.vimeoTimestamp;

    // if the last event was a play event, add the time difference to the vimeo timestamp
    if (event.status === WatchPartyEventStatus.PLAY) {
      const localDate: moment.Moment = moment();
      const eventDate: moment.Moment = moment(event.clientTimestamp);
      const timeDifference: number = localDate.diff(eventDate, 'seconds');
      vimeoTimestamp += timeDifference;
    }

    if (vimeoTimestamp > this.videoDetails.duration) {
      vimeoTimestamp = this.videoDetails.duration;
    }

    this.vimeoPlayer.setCurrentTime(vimeoTimestamp)
      .then(() => this.vimeoPlayer.play());
  }

  private processRemoteEvent(event: WatchPartyEventDTO): void {
    const localTimestamp: moment.Moment = moment(this.currentUserLastSentEvent?.clientTimestamp);
    const remoteTimestamp: moment.Moment = moment(event.clientTimestamp);

    // ignore current user's latest local event if it is the same as the remote event
    if (localTimestamp.isSame(remoteTimestamp)) {
      return;
    }

    this.isProcessingRemoteEvent = true;

    let playerActionPromises: Promise<any>[] = [];

    this.vimeoPlayer.getCurrentTime()
      .then((timestamp: number) => {
        if (timestamp !== event.vimeoTimestamp) {
          playerActionPromises.push(this.vimeoPlayer.setCurrentTime(event.vimeoTimestamp));
        }

        switch (event.status) {
          case WatchPartyEventStatus.PLAY:
            playerActionPromises.push(this.vimeoPlayer.play());
            break;
          case WatchPartyEventStatus.STOP:
            playerActionPromises.push(this.vimeoPlayer.pause());
            break;
        }

        return Promise.all(playerActionPromises);
      });
  }
}
