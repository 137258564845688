import {
  Component,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
  ViewContainerRef
} from '@angular/core';
import { StateService } from '@uirouter/core';
import { State } from 'app/common/State';
import { SeniorActivityCriteriaDTO } from 'app/data/dto/senior/SeniorActivityCriteriaDTO';
import { SeniorActivityDTO } from 'app/data/dto/senior/SeniorActivityDTO';
import { UserDTO } from 'app/data/dto/user/UserDTO';
import { ApplicationModel } from 'app/model/ApplicationModel';
import { SeniorModel } from 'app/model/SeniorModel';
import { UserModel } from 'app/model/UserModel';
import { endOfDay } from 'date-fns';
import { AccessControlModel } from 'app/model/AccessControlModel';
import { PortalUtil } from 'app/util/PortalUtil';
import { MainLayoutComponent } from 'app/component/view/main/MainLayoutComponent';
import { TemplatePortal } from '@angular/cdk/portal';
import { TourWizardService, TourWizardStep } from 'ngx-tour-wizard';
import { Observable } from 'rxjs';
import { first, switchMap, tap } from 'rxjs/operators';
import { ViewUtil } from 'app/util/ViewUtil';
import { TourWizardAnchor } from 'app/data/local/TourWizardAnchor';
import { NgxPopperjsPlacements } from 'ngx-popperjs';
import { UserDetailsDTO } from 'app/data/dto/user/UserDetailsDTO';
import { BreakpointObserver } from '@angular/cdk/layout';

@Component({
  selector: 'app-dashboard-senior',
  templateUrl: './DashboardSeniorComponent.html',
  styleUrls: [ './DashboardSeniorComponent.scss' ]
})
export class DashboardSeniorComponent implements OnInit, OnDestroy {
  @ViewChild('headingTemplate', { static: true })
  private readonly headingTemplate: TemplateRef<any>;

  public currentUser: UserDTO;

  public seniorActivity: SeniorActivityDTO;

  private onboardingTourStepList: TourWizardStep[] = [];

  private readonly canDisplayOnboardingTour: boolean = false;

  public readonly TourWizardAnchor: typeof TourWizardAnchor = TourWizardAnchor;

  constructor(private userModel: UserModel,
              private applicationModel: ApplicationModel,
              private stateService: StateService,
              private seniorModel: SeniorModel,
              public accessControlModel: AccessControlModel,
              private portalUtil: PortalUtil,
              private viewContainerRef: ViewContainerRef,
              private viewUtil: ViewUtil,
              private tourWizardService: TourWizardService,
              private breakpointObserver: BreakpointObserver) {
    this.currentUser = this.userModel.currentUser;

    this.canDisplayOnboardingTour = this.breakpointObserver.isMatched('(min-width: 768px)');

    this.onboardingTourStepList = [
      {
        anchorId: TourWizardAnchor.SIDEBAR,
        title: this.viewUtil.translateInstant('DOMAIN.ONBOARDING_TOUR.STEP_SIDEBAR.TITLE'),
        content: this.viewUtil.translateInstant('DOMAIN.ONBOARDING_TOUR.STEP_SIDEBAR.DESCRIPTION'),
        popperSettings: {
          placement: NgxPopperjsPlacements.RIGHT
        }
      },
      {
        anchorId: TourWizardAnchor.CONTROL_MENU,
        title: this.viewUtil.translateInstant('DOMAIN.ONBOARDING_TOUR.STEP_CONTROL_MENU.TITLE'),
        content: this.viewUtil.translateInstant('DOMAIN.ONBOARDING_TOUR.STEP_CONTROL_MENU.DESCRIPTION'),
        popperSettings: {
          placement: NgxPopperjsPlacements.BOTTOM
        }
      },
      {
        anchorId: TourWizardAnchor.STATS,
        title: this.viewUtil.translateInstant('DOMAIN.ONBOARDING_TOUR.STEP_STATS.TITLE'),
        content: this.viewUtil.translateInstant('DOMAIN.ONBOARDING_TOUR.STEP_STATS.DESCRIPTION'),
        popperSettings: {
          placement: NgxPopperjsPlacements.BOTTOM
        }
      },
      {
        anchorId: TourWizardAnchor.HAPPENING_NOW,
        title: this.viewUtil.translateInstant('DOMAIN.ONBOARDING_TOUR.STEP_HAPPENING_NOW.TITLE'),
        content: this.viewUtil.translateInstant('DOMAIN.ONBOARDING_TOUR.STEP_HAPPENING_NOW.DESCRIPTION'),
        popperSettings: {
          placement: NgxPopperjsPlacements.AUTO
        }
      },
      {
        anchorId: TourWizardAnchor.RECOMMENDED,
        title: this.viewUtil.translateInstant('DOMAIN.ONBOARDING_TOUR.STEP_RECOMMENDED.TITLE'),
        content: this.viewUtil.translateInstant('DOMAIN.ONBOARDING_TOUR.STEP_RECOMMENDED.DESCRIPTION'),
        popperSettings: {
          placement: NgxPopperjsPlacements.AUTO
        }
      }
    ];
  }

  public ngOnInit(): void {
    this.applicationModel.selectSideBarItemWithState(State.MAIN.DASHBOARD);

    this.portalUtil.attachPortalTo(
      MainLayoutComponent.PORTAL_OUTLET.HEADING,
      new TemplatePortal(this.headingTemplate, this.viewContainerRef)
    );

    // this component is available also for guests,
    // so we need to check if we are logged in or not
    if (this.accessControlModel.isFullAccess) {
      this.getSeniorActivity()
        .subscribe(() => {
          this.startOnboardingTour();
        });
    }
  }

  public ngOnDestroy(): void {
    this.portalUtil.detachPortalFrom(MainLayoutComponent.PORTAL_OUTLET.HEADING);
    this.tourWizardService.end();
  }

  public getSeniorActivity(): Observable<SeniorActivityDTO> {
    const dateNow: Date = new Date();
    const dateFrom: Date = new Date();
    dateFrom.setDate(1); //start of the month
    dateFrom.setHours(0, 0, 0, 0); //midnight

    const criteria: SeniorActivityCriteriaDTO = new SeniorActivityCriteriaDTO();
    criteria.dateFrom = dateFrom;
    criteria.dateTo = endOfDay(dateNow);

    return this.seniorModel.getCurrentSeniorActivity(criteria).pipe(
      tap((activity) => {
        this.seniorActivity = activity;
      })
    );
  }

  public onSeeAllClick(): void {
    this.stateService.go(State.MAIN.SENIOR.MY_ACTIVITY.LIST);
  }

  private startOnboardingTour() {
    if (this.currentUser?.hasCompletedTutorial || !this.canDisplayOnboardingTour) {
      return;
    }

    // remove stats tour step if there is no activity
    if (!this.seniorActivity) {
      this.onboardingTourStepList = this.onboardingTourStepList.filter((step: TourWizardStep) => step.anchorId !== TourWizardAnchor.STATS);
    }

    setTimeout(() => {
      this.tourWizardService.initialize(this.onboardingTourStepList);
      this.tourWizardService.start();

      // add progress per step
      this.onboardingTourStepList.forEach((step: TourWizardStep, index: number) => {
        const tourStepElement: HTMLElement = document.querySelector('[tourWizardAnchor="' + step.anchorId + '"] + tour-wizard-popper-component');
        tourStepElement.classList.add('tour-wizard-progress');
        tourStepElement.style.setProperty('--current-step', (index + 1).toString());
        tourStepElement.style.setProperty('--all-steps', (this.onboardingTourStepList.length).toString());
      });

      this.tourWizardService.end$.pipe(
        first(),
        switchMap(() => this.userModel.setCurrentUserTutorialCompletion(true))
      ).subscribe((userDetails: UserDetailsDTO) => {
        this.currentUser.hasCompletedTutorial = userDetails.hasCompletedTutorial;
      });
    });
  }
}
